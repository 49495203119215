import { USER_LANGS, USER_ROLES, USER_TIMEZONES, UserLang, UserRole, UserTimezone } from './user.model';
import { ImmutableMap, Prop, QueryParams, RequiredOnly, isBoolean, isObject, isString } from './utility.model';

export const ORGANIZER_ROLES = ['admin'] as const;
export type OrganizerRole = (typeof ORGANIZER_ROLES)[number];

type OrganizerAbilities = {
  organizationId: string;
  role: UserRole;
  taskManagement: boolean;
};

type OrganizerRequiredProps = {
  email: string;
  name: string;
  role: OrganizerRole;
  lang: UserLang;
  tz: UserTimezone;
};

type OrganizerOptionalProps = {
  phoneNumber?: string;
  countryCode?: string;
  memo?: string;
  avatar?: string;
  abilities?: OrganizerAbilities;
};

type OrganizerProvidedProps = {
  organizerId: string;
  updatedAt: number;
  version: number;
};

export type OrganizerQueryParams = QueryParams;

export type OrganizerCreateParams = OrganizerRequiredProps & OrganizerOptionalProps;

export type OrganizerUpdateParams = Partial<Omit<OrganizerRequiredProps, 'email'>> & OrganizerOptionalProps;

export type Organizer = OrganizerRequiredProps & OrganizerOptionalProps & OrganizerProvidedProps;

export function isOrganizerAbilities(value: unknown): value is OrganizerAbilities {
  const sProps: Prop<RequiredOnly<OrganizerAbilities>, string>[] = ['organizationId'];
  const bProps: Prop<RequiredOnly<OrganizerAbilities>, boolean>[] = ['taskManagement'];
  return (
    isObject(value) &&
    sProps.every(prop => isString(value[prop])) &&
    bProps.every(prop => isBoolean(value[prop])) &&
    USER_ROLES.some(v => v === value['role'])
  );
}

export function isOrganizerCreateParams(value: unknown): value is OrganizerCreateParams {
  const sProps: Prop<RequiredOnly<OrganizerCreateParams>, string>[] = ['email', 'name'];
  return (
    isObject(value) &&
    sProps.every(prop => isString(value[prop])) &&
    ORGANIZER_ROLES.some(v => v === value['role']) &&
    USER_LANGS.some(v => v === value['lang']) &&
    USER_TIMEZONES.some(v => v === value['tz'])
  );
}

export function isOrganizerUpdateParams(value: unknown): value is OrganizerUpdateParams {
  const sProps: Prop<OrganizerUpdateParams, string>[] = ['name', 'phoneNumber', 'countryCode', 'memo', 'avatar'];
  return (
    isObject(value) &&
    (sProps.some(prop => isString(value[prop])) ||
      ORGANIZER_ROLES.some(v => v === value['role']) ||
      USER_LANGS.some(v => v === value['lang']) ||
      USER_TIMEZONES.some(v => v === value['tz']) ||
      isOrganizerAbilities(value['abilities']))
  );
}

export class Organizers extends ImmutableMap<string, Organizer> {
  constructor(organizers?: Organizer[] | Iterable<Organizer>) {
    super(organizer => organizer.organizerId, organizers);
  }

  protected clone(organizers?: Organizer[] | Iterable<Organizer>): this {
    return new Organizers(organizers) as this;
  }
}
