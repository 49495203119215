import { Injectable } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { Observable, defer } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthPayload, AuthSession, AuthUser } from '../models/auth.model';
import { AuthGateway } from '../usecases/auth.gateway';

@Injectable()
export class AuthService extends AuthGateway {
  currentAuthenticatedUser(): Observable<AuthUser> {
    return defer(() => Auth.currentAuthenticatedUser());
  }

  currentSession(): Observable<AuthSession> {
    return defer(() => Auth.currentSession()).pipe(
      map(session => ({ token: session.getIdToken().getJwtToken(), payload: session.getIdToken().payload as AuthPayload })),
    );
  }

  signIn(username: string, password: string): Observable<AuthUser> {
    return defer(() => Auth.signIn({ username, password }));
  }

  completeNewPassword(user: AuthUser, password: string): Observable<AuthUser> {
    return defer(() => Auth.completeNewPassword(user, password, false));
  }

  signOut(): Observable<unknown> {
    return defer(() => Auth.signOut());
  }

  forgotPassword(username: string): Observable<unknown> {
    return defer(() => Auth.forgotPassword(username));
  }

  forgotPasswordSubmit(username: string, code: string, password: string): Observable<string> {
    return defer(() => Auth.forgotPasswordSubmit(username, code, password));
  }

  changePassword(user: AuthUser, oldPassword: string, newPassword: string): Observable<'SUCCESS'> {
    return defer(() => Auth.changePassword(user, oldPassword, newPassword));
  }
}
