import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router, UrlTree } from '@angular/router';
import { Observable, noop } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { AuthUsecase } from '../usecases/auth.usecase';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(private _router: Router, private _authUsecase: AuthUsecase) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._authUsecase.authState$.pipe(
      map(authState => authState.status === 'signedIn'),
      tap(canActivate => (canActivate ? noop : this._router.navigate(['']))),
    );
  }

  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    return this._authUsecase.authState$.pipe(
      take(1),
      map(authState => authState.status === 'signedIn'),
      tap(canLoad => (canLoad ? noop : this._router.navigate(['']))),
    );
  }
}
