abstract class AbstractError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = new.target.name;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class NeverError extends AbstractError {
  constructor(value: never) {
    super(`Unexpected value '${value}'. Should have been never.`);
  }
}
