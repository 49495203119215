import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { delay, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { ProgressUsecase } from '../usecases/progress.usecase';

const DISMISS_DELAY = 200;

@Injectable()
export class ProgressInteractor extends ProgressUsecase {
  get progress$(): Observable<boolean> {
    return this._progress.pipe(
      switchMap(progress => (progress.size ? of(true) : of(false).pipe(delay(DISMISS_DELAY)))),
      distinctUntilChanged(),
    );
  }

  private readonly _progress = new BehaviorSubject<Set<string>>(new Set());

  show(): string {
    const id = uuid();
    const set = this._progress.value;
    this._progress.next(set.add(id));
    return id;
  }

  dismiss(id: string): void {
    const set = this._progress.value;
    if (set.delete(id)) {
      this._progress.next(set);
    }
  }
}
