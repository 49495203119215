import { HttpContextToken } from '@angular/common/http';
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import { catchError, concatMap, expand, toArray } from 'rxjs/operators';

export const HIDE_PROGRESS = new HttpContextToken(() => false);

export type RequiredOnly<T> = { [P in keyof T as T[P] extends Required<T>[P] ? P : never]: T[P] };

export type Prop<T, K> = NonNullable<{ [P in keyof T]: T[P] extends K | undefined ? P : never }[keyof T]>;

export type ListResponse<T> = { items: T[]; nextToken?: string };

export type QueryParams = { nextToken?: string };

export type QueryFunction<T extends QueryParams, R> = (params: T) => Observable<ListResponse<R>>;

export function recursiveQuery<T extends QueryParams, R>(func: QueryFunction<T, R>, params: T): Observable<R[]> {
  return func(params).pipe(
    expand(({ nextToken }) => (nextToken ? func({ ...params, nextToken }) : EMPTY)),
    concatMap(({ items }) => items),
    toArray(),
    catchError(() => of([] as R[])),
  );
}

export function isString(value: unknown): value is string {
  return typeof value === 'string';
}

export function isNumber(value: unknown): value is number {
  return typeof value === 'number';
}

export function isBoolean(value: unknown): value is boolean {
  return typeof value === 'boolean';
}

export function isObject(value: unknown): value is Record<string, unknown> {
  return value !== null && typeof value === 'object';
}

export function toStrictEntries<T extends Record<string, unknown>>(value: T): [keyof T, unknown][] {
  return Object.entries(value);
}

export class DistinctSubject<T> extends BehaviorSubject<T> {
  override next(value: T): void {
    if (this.value !== value) {
      super.next(value);
    }
  }
}

export abstract class ImmutableMap<K, V extends { version: number }> {
  private _key: (value: V) => K;
  private _val = new Map<K, V>();

  constructor(keySelector: (value: V) => K, entries: V[] | Iterable<V> = []) {
    this._key = keySelector;
    for (const value of entries) {
      this._val.set(this._key(value), value);
    }
  }

  protected abstract clone(entries?: V[] | Iterable<V>): this;

  set(value: V): this {
    const key = this._key(value);
    const clone = new Map<K, V>(this._val);
    const current = clone.get(key);
    if (!current || current.version < value.version) {
      return this.clone(clone.set(key, value).values());
    }
    return this;
  }

  delete(key: K): this {
    const clone = new Map<K, V>(this._val);
    if (clone.delete(key)) {
      return this.clone(clone.values());
    }
    return this;
  }

  get(key: K): V | undefined {
    return this._val.get(key);
  }

  has(key: K): boolean {
    return this._val.has(key);
  }

  values(): V[] {
    return [...this._val.values()];
  }

  sort(props: (keyof V)[]): this {
    return this.clone(this.values().sort(this.compare(props)));
  }

  compare(props: (keyof V)[]): (a: V, b: V) => number {
    return (a: V, b: V): number => {
      for (const prop of props) {
        if (a[prop] === b[prop]) {
          continue;
        }
        if (typeof a[prop] === 'string' && typeof b[prop] === 'string') {
          return `${a[prop]}`.localeCompare(`${b[prop]}`) > 0 ? 1 : -1;
        }
        return a[prop] > b[prop] ? 1 : -1;
      }
      return 0;
    };
  }
}
