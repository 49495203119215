export type Country = {
  readonly name: string;
  readonly code: string;
  readonly phone: string;
  readonly emoji: string;
};

// cspell: disable
export const COUNTRIES = [
  {
    name: '',
    code: 'none',
    phone: '',
    emoji: '',
  },
  {
    name: 'Afghanistan (‫افغانستان‬‎)',
    code: 'af',
    phone: '+93',
    emoji: '🇦🇫',
  },
  {
    name: 'Albania (Shqipëri)',
    code: 'al',
    phone: '+355',
    emoji: '🇦🇱',
  },
  {
    name: 'Algeria (‫الجزائر‬‎)',
    code: 'dz',
    phone: '+213',
    emoji: '🇩🇿',
  },
  {
    name: 'American Samoa',
    code: 'as',
    phone: '+1684',
    emoji: '🇦🇸',
  },
  {
    name: 'Andorra',
    code: 'ad',
    phone: '+376',
    emoji: '🇦🇩',
  },
  {
    name: 'Angola',
    code: 'ao',
    phone: '+244',
    emoji: '🇦🇴',
  },
  {
    name: 'Anguilla',
    code: 'ai',
    phone: '+1264',
    emoji: '🇦🇮',
  },
  {
    name: 'Antigua and Barbuda',
    code: 'ag',
    phone: '+1268',
    emoji: '🇦🇬',
  },
  {
    name: 'Argentina',
    code: 'ar',
    phone: '+54',
    emoji: '🇦🇷',
  },
  {
    name: 'Armenia (Հայաստան)',
    code: 'am',
    phone: '+374',
    emoji: '🇦🇲',
  },
  {
    name: 'Aruba',
    code: 'aw',
    phone: '+297',
    emoji: '🇦🇼',
  },
  {
    name: 'Australia',
    code: 'au',
    phone: '+61',
    emoji: '🇦🇺',
  },
  {
    name: 'Austria (Österreich)',
    code: 'at',
    phone: '+43',
    emoji: '🇦🇹',
  },
  {
    name: 'Azerbaijan (Azərbaycan)',
    code: 'az',
    phone: '+994',
    emoji: '🇦🇿',
  },
  {
    name: 'Bahamas',
    code: 'bs',
    phone: '+1242',
    emoji: '🇧🇸',
  },
  {
    name: 'Bahrain (‫البحرين‬‎)',
    code: 'bh',
    phone: '+973',
    emoji: '🇧🇭',
  },
  {
    name: 'Bangladesh (বাংলাদেশ)',
    code: 'bd',
    phone: '+880',
    emoji: '🇧🇩',
  },
  {
    name: 'Barbados',
    code: 'bb',
    phone: '+1246',
    emoji: '🇧🇧',
  },
  {
    name: 'Belarus (Беларусь)',
    code: 'by',
    phone: '+375',
    emoji: '🇧🇾',
  },
  {
    name: 'Belgium (België)',
    code: 'be',
    phone: '+32',
    emoji: '🇧🇪',
  },
  {
    name: 'Belize',
    code: 'bz',
    phone: '+501',
    emoji: '🇧🇿',
  },
  {
    name: 'Benin (Bénin)',
    code: 'bj',
    phone: '+229',
    emoji: '🇧🇯',
  },
  {
    name: 'Bermuda',
    code: 'bm',
    phone: '+1441',
    emoji: '🇧🇲',
  },
  {
    name: 'Bhutan (འབྲུག)',
    code: 'bt',
    phone: '+975',
    emoji: '🇧🇹',
  },
  {
    name: 'Bolivia',
    code: 'bo',
    phone: '+591',
    emoji: '🇧🇴',
  },
  {
    name: 'Bosnia and Herzegovina (Босна и Херцеговина)',
    code: 'ba',
    phone: '+387',
    emoji: '🇧🇦',
  },
  {
    name: 'Botswana',
    code: 'bw',
    phone: '+267',
    emoji: '🇧🇼',
  },
  {
    name: 'Brazil (Brasil)',
    code: 'br',
    phone: '+55',
    emoji: '🇧🇷',
  },
  {
    name: 'British Indian Ocean Territory',
    code: 'io',
    phone: '+246',
    emoji: '🇮🇴',
  },
  {
    name: 'British Virgin Islands',
    code: 'vg',
    phone: '+1284',
    emoji: '🇻🇬',
  },
  {
    name: 'Brunei',
    code: 'bn',
    phone: '+673',
    emoji: '🇧🇳',
  },
  {
    name: 'Bulgaria (България)',
    code: 'bg',
    phone: '+359',
    emoji: '🇧🇬',
  },
  {
    name: 'Burkina Faso',
    code: 'bf',
    phone: '+226',
    emoji: '🇧🇫',
  },
  {
    name: 'Burundi (Uburundi)',
    code: 'bi',
    phone: '+257',
    emoji: '🇧🇮',
  },
  {
    name: 'Cambodia (កម្ពុជា)',
    code: 'kh',
    phone: '+855',
    emoji: '🇰🇭',
  },
  {
    name: 'Cameroon (Cameroun)',
    code: 'cm',
    phone: '+237',
    emoji: '🇨🇲',
  },
  {
    name: 'Canada',
    code: 'ca',
    phone: '+1',
    emoji: '🇨🇦',
  },
  {
    name: 'Cape Verde (Kabu Verdi)',
    code: 'cv',
    phone: '+238',
    emoji: '🇨🇻',
  },
  {
    name: 'Caribbean Netherlands',
    code: 'bq',
    phone: '+599',
    emoji: '🇧🇶',
  },
  {
    name: 'Cayman Islands',
    code: 'ky',
    phone: '+1345',
    emoji: '🇰🇾',
  },
  {
    name: 'Central African Republic (République centrafricaine)',
    code: 'cf',
    phone: '+236',
    emoji: '🇨🇫',
  },
  {
    name: 'Chad (Tchad)',
    code: 'td',
    phone: '+235',
    emoji: '🇹🇩',
  },
  {
    name: 'Chile',
    code: 'cl',
    phone: '+56',
    emoji: '🇨🇱',
  },
  {
    name: 'China (中国)',
    code: 'cn',
    phone: '+86',
    emoji: '🇨🇳',
  },
  {
    name: 'Christmas Island',
    code: 'cx',
    phone: '+61',
    emoji: '🇨🇽',
  },
  {
    name: 'Cocos (Keeling) Islands',
    code: 'cc',
    phone: '+61',
    emoji: '🇨🇨',
  },
  {
    name: 'Colombia',
    code: 'co',
    phone: '+57',
    emoji: '🇨🇴',
  },
  {
    name: 'Comoros (‫جزر القمر‬‎)',
    code: 'km',
    phone: '+269',
    emoji: '🇰🇲',
  },
  {
    name: 'Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)',
    code: 'cd',
    phone: '+243',
    emoji: '🇨🇩',
  },
  {
    name: 'Congo (Republic) (Congo-Brazzaville)',
    code: 'cg',
    phone: '+242',
    emoji: '🇨🇬',
  },
  {
    name: 'Cook Islands',
    code: 'ck',
    phone: '+682',
    emoji: '🇨🇰',
  },
  {
    name: 'Costa Rica',
    code: 'cr',
    phone: '+506',
    emoji: '🇨🇷',
  },
  {
    name: 'Côte d’Ivoire',
    code: 'ci',
    phone: '+225',
    emoji: '🇨🇮',
  },
  {
    name: 'Croatia (Hrvatska)',
    code: 'hr',
    phone: '+385',
    emoji: '🇭🇷',
  },
  {
    name: 'Cuba',
    code: 'cu',
    phone: '+53',
    emoji: '🇨🇺',
  },
  {
    name: 'Curaçao',
    code: 'cw',
    phone: '+599',
    emoji: '🇨🇼',
  },
  {
    name: 'Cyprus (Κύπρος)',
    code: 'cy',
    phone: '+357',
    emoji: '🇨🇾',
  },
  {
    name: 'Czech Republic (Česká republika)',
    code: 'cz',
    phone: '+420',
    emoji: '🇨🇿',
  },
  {
    name: 'Denmark (Danmark)',
    code: 'dk',
    phone: '+45',
    emoji: '🇩🇰',
  },
  {
    name: 'Djibouti',
    code: 'dj',
    phone: '+253',
    emoji: '🇩🇯',
  },
  {
    name: 'Dominica',
    code: 'dm',
    phone: '+1767',
    emoji: '🇩🇲',
  },
  {
    name: 'Dominican Republic (República Dominicana)',
    code: 'do',
    phone: '+1809',
    emoji: '🇩🇴',
  },
  {
    name: 'Ecuador',
    code: 'ec',
    phone: '+593',
    emoji: '🇪🇨',
  },
  {
    name: 'Egypt (‫مصر‬‎)',
    code: 'eg',
    phone: '+20',
    emoji: '🇪🇬',
  },
  {
    name: 'El Salvador',
    code: 'sv',
    phone: '+503',
    emoji: '🇸🇻',
  },
  {
    name: 'Equatorial Guinea (Guinea Ecuatorial)',
    code: 'gq',
    phone: '+240',
    emoji: '🇬🇶',
  },
  {
    name: 'Eritrea',
    code: 'er',
    phone: '+291',
    emoji: '🇪🇷',
  },
  {
    name: 'Estonia (Eesti)',
    code: 'ee',
    phone: '+372',
    emoji: '🇪🇪',
  },
  {
    name: 'Ethiopia',
    code: 'et',
    phone: '+251',
    emoji: '🇪🇹',
  },
  {
    name: 'Falkland Islands (Islas Malvinas)',
    code: 'fk',
    phone: '+500',
    emoji: '🇫🇰',
  },
  {
    name: 'Faroe Islands (Føroyar)',
    code: 'fo',
    phone: '+298',
    emoji: '🇫🇴',
  },
  {
    name: 'Fiji',
    code: 'fj',
    phone: '+679',
    emoji: '🇫🇯',
  },
  {
    name: 'Finland (Suomi)',
    code: 'fi',
    phone: '+358',
    emoji: '🇫🇮',
  },
  {
    name: 'France',
    code: 'fr',
    phone: '+33',
    emoji: '🇫🇷',
  },
  {
    name: 'French Guiana (Guyane française)',
    code: 'gf',
    phone: '+594',
    emoji: '🇬🇫',
  },
  {
    name: 'French Polynesia (Polynésie française)',
    code: 'pf',
    phone: '+689',
    emoji: '🇵🇫',
  },
  {
    name: 'Gabon',
    code: 'ga',
    phone: '+241',
    emoji: '🇬🇦',
  },
  {
    name: 'Gambia',
    code: 'gm',
    phone: '+220',
    emoji: '🇬🇲',
  },
  {
    name: 'Georgia (საქართველო)',
    code: 'ge',
    phone: '+995',
    emoji: '🇬🇪',
  },
  {
    name: 'Germany (Deutschland)',
    code: 'de',
    phone: '+49',
    emoji: '🇩🇪',
  },
  {
    name: 'Ghana (Gaana)',
    code: 'gh',
    phone: '+233',
    emoji: '🇬🇭',
  },
  {
    name: 'Gibraltar',
    code: 'gi',
    phone: '+350',
    emoji: '🇬🇮',
  },
  {
    name: 'Greece (Ελλάδα)',
    code: 'gr',
    phone: '+30',
    emoji: '🇬🇷',
  },
  {
    name: 'Greenland (Kalaallit Nunaat)',
    code: 'gl',
    phone: '+299',
    emoji: '🇬🇱',
  },
  {
    name: 'Grenada',
    code: 'gd',
    phone: '+1473',
    emoji: '🇬🇩',
  },
  {
    name: 'Guadeloupe',
    code: 'gp',
    phone: '+590',
    emoji: '🇬🇵',
  },
  {
    name: 'Guam',
    code: 'gu',
    phone: '+1671',
    emoji: '🇬🇺',
  },
  {
    name: 'Guatemala',
    code: 'gt',
    phone: '+502',
    emoji: '🇬🇹',
  },
  {
    name: 'Guernsey',
    code: 'gg',
    phone: '+44',
    emoji: '🇬🇬',
  },
  {
    name: 'Guinea (Guinée)',
    code: 'gn',
    phone: '+224',
    emoji: '🇬🇳',
  },
  {
    name: 'Guinea-Bissau (Guiné Bissau)',
    code: 'gw',
    phone: '+245',
    emoji: '🇬🇼',
  },
  {
    name: 'Guyana',
    code: 'gy',
    phone: '+592',
    emoji: '🇬🇾',
  },
  {
    name: 'Haiti',
    code: 'ht',
    phone: '+509',
    emoji: '🇭🇹',
  },
  {
    name: 'Honduras',
    code: 'hn',
    phone: '+504',
    emoji: '🇭🇳',
  },
  {
    name: 'Hong Kong (香港)',
    code: 'hk',
    phone: '+852',
    emoji: '🇭🇰',
  },
  {
    name: 'Hungary (Magyarország)',
    code: 'hu',
    phone: '+36',
    emoji: '🇭🇺',
  },
  {
    name: 'Iceland (Ísland)',
    code: 'is',
    phone: '+354',
    emoji: '🇮🇸',
  },
  {
    name: 'India (भारत)',
    code: 'in',
    phone: '+91',
    emoji: '🇮🇳',
  },
  {
    name: 'Indonesia',
    code: 'id',
    phone: '+62',
    emoji: '🇮🇩',
  },
  {
    name: 'Iran (‫ایران‬‎)',
    code: 'ir',
    phone: '+98',
    emoji: '🇮🇷',
  },
  {
    name: 'Iraq (‫العراق‬‎)',
    code: 'iq',
    phone: '+964',
    emoji: '🇮🇶',
  },
  {
    name: 'Ireland',
    code: 'ie',
    phone: '+353',
    emoji: '🇮🇪',
  },
  {
    name: 'Isle of Man',
    code: 'im',
    phone: '+44',
    emoji: '🇮🇲',
  },
  {
    name: 'Israel (‫ישראל‬‎)',
    code: 'il',
    phone: '+972',
    emoji: '🇮🇱',
  },
  {
    name: 'Italy (Italia)',
    code: 'it',
    phone: '+39',
    emoji: '🇮🇹',
  },
  {
    name: 'Jamaica',
    code: 'jm',
    phone: '+1876',
    emoji: '🇯🇲',
  },
  {
    name: 'Japan (日本)',
    code: 'jp',
    phone: '+81',
    emoji: '🇯🇵',
  },
  {
    name: 'Jersey',
    code: 'je',
    phone: '+44',
    emoji: '🇯🇪',
  },
  {
    name: 'Jordan (‫الأردن‬‎)',
    code: 'jo',
    phone: '+962',
    emoji: '🇯🇴',
  },
  {
    name: 'Kazakhstan (Казахстан)',
    code: 'kz',
    phone: '+7',
    emoji: '🇰🇿',
  },
  {
    name: 'Kenya',
    code: 'ke',
    phone: '+254',
    emoji: '🇰🇪',
  },
  {
    name: 'Kiribati',
    code: 'ki',
    phone: '+686',
    emoji: '🇰🇮',
  },
  {
    name: 'Kosovo',
    code: 'xk',
    phone: '+383',
    emoji: '🇽🇰',
  },
  {
    name: 'Kuwait (‫الكويت‬‎)',
    code: 'kw',
    phone: '+965',
    emoji: '🇰🇼',
  },
  {
    name: 'Kyrgyzstan (Кыргызстан)',
    code: 'kg',
    phone: '+996',
    emoji: '🇰🇬',
  },
  {
    name: 'Laos (ລາວ)',
    code: 'la',
    phone: '+856',
    emoji: '🇱🇦',
  },
  {
    name: 'Latvia (Latvija)',
    code: 'lv',
    phone: '+371',
    emoji: '🇱🇻',
  },
  {
    name: 'Lebanon (‫لبنان‬‎)',
    code: 'lb',
    phone: '+961',
    emoji: '🇱🇧',
  },
  {
    name: 'Lesotho',
    code: 'ls',
    phone: '+266',
    emoji: '🇱🇸',
  },
  {
    name: 'Liberia',
    code: 'lr',
    phone: '+231',
    emoji: '🇱🇷',
  },
  {
    name: 'Libya (‫ليبيا‬‎)',
    code: 'ly',
    phone: '+218',
    emoji: '🇱🇾',
  },
  {
    name: 'Liechtenstein',
    code: 'li',
    phone: '+423',
    emoji: '🇱🇮',
  },
  {
    name: 'Lithuania (Lietuva)',
    code: 'lt',
    phone: '+370',
    emoji: '🇱🇹',
  },
  {
    name: 'Luxembourg',
    code: 'lu',
    phone: '+352',
    emoji: '🇱🇺',
  },
  {
    name: 'Macau (澳門)',
    code: 'mo',
    phone: '+853',
    emoji: '🇲🇴',
  },
  {
    name: 'Macedonia (FYROM) (Македонија)',
    code: 'mk',
    phone: '+389',
    emoji: '🇲🇰',
  },
  {
    name: 'Madagascar (Madagasikara)',
    code: 'mg',
    phone: '+261',
    emoji: '🇲🇬',
  },
  {
    name: 'Malawi',
    code: 'mw',
    phone: '+265',
    emoji: '🇲🇼',
  },
  {
    name: 'Malaysia',
    code: 'my',
    phone: '+60',
    emoji: '🇲🇾',
  },
  {
    name: 'Maldives',
    code: 'mv',
    phone: '+960',
    emoji: '🇲🇻',
  },
  {
    name: 'Mali',
    code: 'ml',
    phone: '+223',
    emoji: '🇲🇱',
  },
  {
    name: 'Malta',
    code: 'mt',
    phone: '+356',
    emoji: '🇲🇹',
  },
  {
    name: 'Marshall Islands',
    code: 'mh',
    phone: '+692',
    emoji: '🇲🇭',
  },
  {
    name: 'Martinique',
    code: 'mq',
    phone: '+596',
    emoji: '🇲🇶',
  },
  {
    name: 'Mauritania (‫موريتانيا‬‎)',
    code: 'mr',
    phone: '+222',
    emoji: '🇲🇷',
  },
  {
    name: 'Mauritius (Moris)',
    code: 'mu',
    phone: '+230',
    emoji: '🇲🇺',
  },
  {
    name: 'Mayotte',
    code: 'yt',
    phone: '+262',
    emoji: '🇾🇹',
  },
  {
    name: 'Mexico (México)',
    code: 'mx',
    phone: '+52',
    emoji: '🇲🇽',
  },
  {
    name: 'Micronesia',
    code: 'fm',
    phone: '+691',
    emoji: '🇫🇲',
  },
  {
    name: 'Moldova (Republica Moldova)',
    code: 'md',
    phone: '+373',
    emoji: '🇲🇩',
  },
  {
    name: 'Monaco',
    code: 'mc',
    phone: '+377',
    emoji: '🇲🇨',
  },
  {
    name: 'Mongolia (Монгол)',
    code: 'mn',
    phone: '+976',
    emoji: '🇲🇳',
  },
  {
    name: 'Montenegro (Crna Gora)',
    code: 'me',
    phone: '+382',
    emoji: '🇲🇪',
  },
  {
    name: 'Montserrat',
    code: 'ms',
    phone: '+1664',
    emoji: '🇲🇸',
  },
  {
    name: 'Morocco (‫المغرب‬‎)',
    code: 'ma',
    phone: '+212',
    emoji: '🇲🇦',
  },
  {
    name: 'Mozambique (Moçambique)',
    code: 'mz',
    phone: '+258',
    emoji: '🇲🇿',
  },
  {
    name: 'Myanmar (Burma) (မြန်မာ)',
    code: 'mm',
    phone: '+95',
    emoji: '🇲🇲',
  },
  {
    name: 'Namibia (Namibië)',
    code: 'na',
    phone: '+264',
    emoji: '🇳🇦',
  },
  {
    name: 'Nauru',
    code: 'nr',
    phone: '+674',
    emoji: '🇳🇷',
  },
  {
    name: 'Nepal (नेपाल)',
    code: 'np',
    phone: '+977',
    emoji: '🇳🇵',
  },
  {
    name: 'Netherlands (Nederland)',
    code: 'nl',
    phone: '+31',
    emoji: '🇳🇱',
  },
  {
    name: 'New Caledonia (Nouvelle-Calédonie)',
    code: 'nc',
    phone: '+687',
    emoji: '🇳🇨',
  },
  {
    name: 'New Zealand',
    code: 'nz',
    phone: '+64',
    emoji: '🇳🇿',
  },
  {
    name: 'Nicaragua',
    code: 'ni',
    phone: '+505',
    emoji: '🇳🇮',
  },
  {
    name: 'Niger (Nijar)',
    code: 'ne',
    phone: '+227',
    emoji: '🇳🇪',
  },
  {
    name: 'Nigeria',
    code: 'ng',
    phone: '+234',
    emoji: '🇳🇬',
  },
  {
    name: 'Niue',
    code: 'nu',
    phone: '+683',
    emoji: '🇳🇺',
  },
  {
    name: 'Norfolk Island',
    code: 'nf',
    phone: '+672',
    emoji: '🇳🇫',
  },
  {
    name: 'North Korea (조선 민주주의 인민 공화국)',
    code: 'kp',
    phone: '+850',
    emoji: '🇰🇵',
  },
  {
    name: 'Northern Mariana Islands',
    code: 'mp',
    phone: '+1670',
    emoji: '🇲🇵',
  },
  {
    name: 'Norway (Norge)',
    code: 'no',
    phone: '+47',
    emoji: '🇳🇴',
  },
  {
    name: 'Oman (‫عُمان‬‎)',
    code: 'om',
    phone: '+968',
    emoji: '🇴🇲',
  },
  {
    name: 'Pakistan (‫پاکستان‬‎)',
    code: 'pk',
    phone: '+92',
    emoji: '🇵🇰',
  },
  {
    name: 'Palau',
    code: 'pw',
    phone: '+680',
    emoji: '🇵🇼',
  },
  {
    name: 'Palestine (‫فلسطين‬‎)',
    code: 'ps',
    phone: '+970',
    emoji: '🇵🇸',
  },
  {
    name: 'Panama (Panamá)',
    code: 'pa',
    phone: '+507',
    emoji: '🇵🇦',
  },
  {
    name: 'Papua New Guinea',
    code: 'pg',
    phone: '+675',
    emoji: '🇵🇬',
  },
  {
    name: 'Paraguay',
    code: 'py',
    phone: '+595',
    emoji: '🇵🇾',
  },
  {
    name: 'Peru (Perú)',
    code: 'pe',
    phone: '+51',
    emoji: '🇵🇪',
  },
  {
    name: 'Philippines',
    code: 'ph',
    phone: '+63',
    emoji: '🇵🇭',
  },
  {
    name: 'Poland (Polska)',
    code: 'pl',
    phone: '+48',
    emoji: '🇵🇱',
  },
  {
    name: 'Portugal',
    code: 'pt',
    phone: '+351',
    emoji: '🇵🇹',
  },
  {
    name: 'Puerto Rico',
    code: 'pr',
    phone: '+1787',
    emoji: '🇵🇷',
  },
  {
    name: 'Qatar (‫قطر‬‎)',
    code: 'qa',
    phone: '+974',
    emoji: '🇶🇦',
  },
  {
    name: 'Réunion (La Réunion)',
    code: 're',
    phone: '+262',
    emoji: '🇷🇪',
  },
  {
    name: 'Romania (România)',
    code: 'ro',
    phone: '+40',
    emoji: '🇷🇴',
  },
  {
    name: 'Russia (Россия)',
    code: 'ru',
    phone: '+7',
    emoji: '🇷🇺',
  },
  {
    name: 'Rwanda',
    code: 'rw',
    phone: '+250',
    emoji: '🇷🇼',
  },
  {
    name: 'Saint Barthélemy (Saint-Barthélemy)',
    code: 'bl',
    phone: '+590',
    emoji: '🇧🇱',
  },
  {
    name: 'Saint Helena',
    code: 'sh',
    phone: '+290',
    emoji: '🇸🇭',
  },
  {
    name: 'Saint Kitts and Nevis',
    code: 'kn',
    phone: '+1869',
    emoji: '🇰🇳',
  },
  {
    name: 'Saint Lucia',
    code: 'lc',
    phone: '+1758',
    emoji: '🇱🇨',
  },
  {
    name: 'Saint Martin (Saint-Martin (partie française))',
    code: 'mf',
    phone: '+590',
    emoji: '🇲🇫',
  },
  {
    name: 'Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)',
    code: 'pm',
    phone: '+508',
    emoji: '🇵🇲',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    code: 'vc',
    phone: '+1784',
    emoji: '🇻🇨',
  },
  {
    name: 'Samoa',
    code: 'ws',
    phone: '+685',
    emoji: '🇼🇸',
  },
  {
    name: 'San Marino',
    code: 'sm',
    phone: '+378',
    emoji: '🇸🇲',
  },
  {
    name: 'São Tomé and Príncipe (São Tomé e Príncipe)',
    code: 'st',
    phone: '+239',
    emoji: '🇸🇹',
  },
  {
    name: 'Saudi Arabia (‫المملكة العربية السعودية‬‎)',
    code: 'sa',
    phone: '+966',
    emoji: '🇸🇦',
  },
  {
    name: 'Senegal (Sénégal)',
    code: 'sn',
    phone: '+221',
    emoji: '🇸🇳',
  },
  {
    name: 'Serbia (Србија)',
    code: 'rs',
    phone: '+381',
    emoji: '🇷🇸',
  },
  {
    name: 'Seychelles',
    code: 'sc',
    phone: '+248',
    emoji: '🇸🇨',
  },
  {
    name: 'Sierra Leone',
    code: 'sl',
    phone: '+232',
    emoji: '🇸🇱',
  },
  {
    name: 'Singapore',
    code: 'sg',
    phone: '+65',
    emoji: '🇸🇬',
  },
  {
    name: 'Sint Maarten',
    code: 'sx',
    phone: '+1721',
    emoji: '🇸🇽',
  },
  {
    name: 'Slovakia (Slovensko)',
    code: 'sk',
    phone: '+421',
    emoji: '🇸🇰',
  },
  {
    name: 'Slovenia (Slovenija)',
    code: 'si',
    phone: '+386',
    emoji: '🇸🇮',
  },
  {
    name: 'Solomon Islands',
    code: 'sb',
    phone: '+677',
    emoji: '🇸🇧',
  },
  {
    name: 'Somalia (Soomaaliya)',
    code: 'so',
    phone: '+252',
    emoji: '🇸🇴',
  },
  {
    name: 'South Africa',
    code: 'za',
    phone: '+27',
    emoji: '🇿🇦',
  },
  {
    name: 'South Korea (대한민국)',
    code: 'kr',
    phone: '+82',
    emoji: '🇰🇷',
  },
  {
    name: 'South Sudan (‫جنوب السودان‬‎)',
    code: 'ss',
    phone: '+211',
    emoji: '🇸🇸',
  },
  {
    name: 'Spain (España)',
    code: 'es',
    phone: '+34',
    emoji: '🇪🇸',
  },
  {
    name: 'Sri Lanka (ශ්‍රී ලංකාව)',
    code: 'lk',
    phone: '+94',
    emoji: '🇱🇰',
  },
  {
    name: 'Sudan (‫السودان‬‎)',
    code: 'sd',
    phone: '+249',
    emoji: '🇸🇩',
  },
  {
    name: 'Suriname',
    code: 'sr',
    phone: '+597',
    emoji: '🇸🇷',
  },
  {
    name: 'Svalbard and Jan Mayen',
    code: 'sj',
    phone: '+47',
    emoji: '🇸🇯',
  },
  {
    name: 'Swaziland',
    code: 'sz',
    phone: '+268',
    emoji: '🇸🇿',
  },
  {
    name: 'Sweden (Sverige)',
    code: 'se',
    phone: '+46',
    emoji: '🇸🇪',
  },
  {
    name: 'Switzerland (Schweiz)',
    code: 'ch',
    phone: '+41',
    emoji: '🇨🇭',
  },
  {
    name: 'Syria (‫سوريا‬‎)',
    code: 'sy',
    phone: '+963',
    emoji: '🇸🇾',
  },
  {
    name: 'Taiwan (台灣)',
    code: 'tw',
    phone: '+886',
    emoji: '🇹🇼',
  },
  {
    name: 'Tajikistan',
    code: 'tj',
    phone: '+992',
    emoji: '🇹🇯',
  },
  {
    name: 'Tanzania',
    code: 'tz',
    phone: '+255',
    emoji: '🇹🇿',
  },
  {
    name: 'Thailand (ไทย)',
    code: 'th',
    phone: '+66',
    emoji: '🇹🇭',
  },
  {
    name: 'Timor-Leste',
    code: 'tl',
    phone: '+670',
    emoji: '🇹🇱',
  },
  {
    name: 'Togo',
    code: 'tg',
    phone: '+228',
    emoji: '🇹🇬',
  },
  {
    name: 'Tokelau',
    code: 'tk',
    phone: '+690',
    emoji: '🇹🇰',
  },
  {
    name: 'Tonga',
    code: 'to',
    phone: '+676',
    emoji: '🇹🇴',
  },
  {
    name: 'Trinidad and Tobago',
    code: 'tt',
    phone: '+1868',
    emoji: '🇹🇹',
  },
  {
    name: 'Tunisia (‫تونس‬‎)',
    code: 'tn',
    phone: '+216',
    emoji: '🇹🇳',
  },
  {
    name: 'Turkey (Türkiye)',
    code: 'tr',
    phone: '+90',
    emoji: '🇹🇷',
  },
  {
    name: 'Turkmenistan',
    code: 'tm',
    phone: '+993',
    emoji: '🇹🇲',
  },
  {
    name: 'Turks and Caicos Islands',
    code: 'tc',
    phone: '+1649',
    emoji: '🇹🇨',
  },
  {
    name: 'Tuvalu',
    code: 'tv',
    phone: '+688',
    emoji: '🇹🇻',
  },
  {
    name: 'U.S. Virgin Islands',
    code: 'vi',
    phone: '+1340',
    emoji: '🇻🇮',
  },
  {
    name: 'Uganda',
    code: 'ug',
    phone: '+256',
    emoji: '🇺🇬',
  },
  {
    name: 'Ukraine (Україна)',
    code: 'ua',
    phone: '+380',
    emoji: '🇺🇦',
  },
  {
    name: 'United Arab Emirates (‫الإمارات العربية المتحدة‬‎)',
    code: 'ae',
    phone: '+971',
    emoji: '🇦🇪',
  },
  {
    name: 'United Kingdom',
    code: 'gb',
    phone: '+44',
    emoji: '🇬🇧',
  },
  {
    name: 'United States',
    code: 'us',
    phone: '+1',
    emoji: '🇺🇸',
  },
  {
    name: 'Uruguay',
    code: 'uy',
    phone: '+598',
    emoji: '🇺🇾',
  },
  {
    name: 'Uzbekistan (Oʻzbekiston)',
    code: 'uz',
    phone: '+998',
    emoji: '🇺🇿',
  },
  {
    name: 'Vanuatu',
    code: 'vu',
    phone: '+678',
    emoji: '🇻🇺',
  },
  {
    name: 'Vatican City (Città del Vaticano)',
    code: 'va',
    phone: '+39',
    emoji: '🇻🇦',
  },
  {
    name: 'Venezuela',
    code: 've',
    phone: '+58',
    emoji: '🇻🇪',
  },
  {
    name: 'Vietnam (Việt Nam)',
    code: 'vn',
    phone: '+84',
    emoji: '🇻🇳',
  },
  {
    name: 'Wallis and Futuna',
    code: 'wf',
    phone: '+681',
    emoji: '🇼🇫',
  },
  {
    name: 'Western Sahara (‫الصحراء الغربية‬‎)',
    code: 'eh',
    phone: '+212',
    emoji: '🇪🇭',
  },
  {
    name: 'Yemen (‫اليمن‬‎)',
    code: 'ye',
    phone: '+967',
    emoji: '🇾🇪',
  },
  {
    name: 'Zambia',
    code: 'zm',
    phone: '+260',
    emoji: '🇿🇲',
  },
  {
    name: 'Zimbabwe',
    code: 'zw',
    phone: '+263',
    emoji: '🇿🇼',
  },
  {
    name: 'Åland Islands',
    code: 'ax',
    phone: '+358',
    emoji: '🇦🇽',
  },
] as readonly Country[];
// cspell: enable
