import { Observable } from 'rxjs';

import { Organization, OrganizationCreateParams, OrganizationQueryParams, OrganizationUpdateParams } from '../models/organization.model';
import { ListResponse } from '../models/utility.model';

export abstract class OrganizationGateway {
  abstract listOrganizations(params: OrganizationQueryParams): Observable<ListResponse<Organization>>;
  abstract createOrganization(params: OrganizationCreateParams): Observable<Organization>;
  abstract getOrganization(organizationId: string): Observable<Organization>;
  abstract updateOrganization(organizationId: string, params: OrganizationUpdateParams): Observable<Organization>;
  abstract deleteOrganization(organizationId: string): Observable<void>;
}
