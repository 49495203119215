import { ImmutableMap, Prop, QueryParams, RequiredOnly, isObject, isString } from './utility.model';

export const USER_TYPES = ['regular', 'contractor'] as const;
export type UserType = (typeof USER_TYPES)[number];

export const USER_ROLES = ['admin', 'leader', 'member'] as const;
export type UserRole = (typeof USER_ROLES)[number];

export const USER_LANGS = ['ja', 'en'] as const;
export type UserLang = (typeof USER_LANGS)[number];

export const USER_TIMEZONES = [
  'America/Chicago',
  'America/New_York',
  'Asia/Kolkata',
  'Asia/Yangon',
  'Asia/Bangkok',
  'Asia/Singapore',
  'Australia/Perth',
  'Asia/Tokyo',
  'Australia/Sydney',
  'Pacific/Auckland',
] as const;
export type UserTimezone = (typeof USER_TIMEZONES)[number];

type UserRequiredProps = {
  name: string;
  role: UserRole;
  lang: UserLang;
  tz: UserTimezone;
};

type UserOptionalProps = {
  email?: string;
  playerCode?: string;
  groupId?: string;
  phoneNumber?: string;
  countryCode?: string;
  memo?: string;
  avatar?: string;
  organizationId?: string;
};

type UserProvidedProps = {
  userId: string;
  organizer?: boolean;
  type: UserType;
  updatedAt: number;
  version: number;
};

export type UserQueryParams = QueryParams & { organizationId?: string };

export type UserCreateParams = UserRequiredProps & UserOptionalProps;

export type UserUpdateParams = Partial<UserRequiredProps> & Omit<UserOptionalProps, 'email'>;

export type UserOrganizationUpdateParams = Pick<UserOptionalProps, 'organizationId'>;

export type User = UserRequiredProps & Omit<UserOptionalProps, 'organizationId'> & UserProvidedProps;

export function isUserCreateParams(value: unknown): value is UserCreateParams {
  const sProps: Prop<RequiredOnly<UserCreateParams>, string>[] = ['name'];
  return (
    isObject(value) &&
    sProps.every(prop => isString(value[prop])) &&
    USER_ROLES.some(v => v === value['role']) &&
    USER_LANGS.some(v => v === value['lang']) &&
    USER_TIMEZONES.some(v => v === value['tz'])
  );
}

export function isUserUpdateParams(value: unknown): value is UserUpdateParams {
  const sProps: Prop<UserUpdateParams, string>[] = ['name', 'playerCode', 'groupId', 'phoneNumber', 'countryCode', 'memo', 'avatar'];
  return (
    isObject(value) &&
    (sProps.some(prop => isString(value[prop])) ||
      USER_ROLES.some(v => v === value['role']) ||
      USER_LANGS.some(v => v === value['lang']) ||
      USER_TIMEZONES.some(v => v === value['tz']))
  );
}

export function isUserOrganizationUpdateParams(value: unknown): value is UserOrganizationUpdateParams {
  const sProps: Prop<UserOrganizationUpdateParams, string>[] = ['organizationId'];
  return isObject(value) && sProps.some(prop => isString(value[prop]));
}

export class Users extends ImmutableMap<string, User> {
  constructor(users?: User[] | Iterable<User>) {
    super(user => user.userId, users);
  }

  protected clone(users?: User[] | Iterable<User>): this {
    return new Users(users) as this;
  }
}
