import { Prop, RequiredOnly, isObject, isString } from './utility.model';

export type MgmtDept = {
  mgmtDeptId: string;
  name: string;
};

export function isMgmtDept(value: unknown): value is MgmtDept {
  const sProps: Prop<RequiredOnly<MgmtDept>, string>[] = ['mgmtDeptId', 'name'];
  return isObject(value) && sProps.every(prop => isString(value[prop]));
}
