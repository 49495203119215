import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Organizer, OrganizerCreateParams, OrganizerQueryParams, OrganizerUpdateParams } from '../models/organizer.model';
import { ListResponse } from '../models/utility.model';
import { OrganizerGateway } from '../usecases/organizer.gateway';

@Injectable()
export class OrganizerService extends OrganizerGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  listOrganizers(params: OrganizerQueryParams): Observable<ListResponse<Organizer>> {
    return this._http.get<ListResponse<Organizer>>('api/com/organizers', { params });
  }

  createOrganizer(params: OrganizerCreateParams): Observable<Organizer> {
    return this._http.post<Organizer>('api/com/organizers', params);
  }

  updateOrganizer(organizerId: string, params: OrganizerUpdateParams): Observable<Organizer> {
    return this._http.put<Organizer>(`api/com/organizers/${organizerId}`, params);
  }

  deleteOrganizer(organizerId: string): Observable<void> {
    return this._http.delete<void>(`api/com/organizers/${organizerId}`);
  }
}
