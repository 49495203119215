import { Observable } from 'rxjs';

import { OrganizerCreateParams, OrganizerUpdateParams, Organizers } from '../models/organizer.model';

export abstract class OrganizerUsecase {
  abstract get organizers$(): Observable<Organizers>;

  abstract createOrganizer(params: OrganizerCreateParams): Observable<never>;
  abstract updateOrganizer(organizerId: string, params: OrganizerUpdateParams): Observable<never>;
  abstract deleteOrganizer(organizerId: string): Observable<never>;

  abstract reload(): void;
}
