import { Injectable } from '@angular/core';

import { DatadogGateway } from '../usecases/datadog.gateway';

@Injectable()
export class DatadogService extends DatadogGateway {
  setUser(email?: string, organization?: string): void {
    if (email) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).DD_RUM?.setUser({ email, organization });
    }
  }
}
