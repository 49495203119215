import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Department, DepartmentCreateParams, DepartmentUpdateParams } from '../models/department.model';
import { DepartmentGateway } from '../usecases/department.gateway';

@Injectable()
export class DepartmentService extends DepartmentGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  createDepartment(params: DepartmentCreateParams): Observable<Department> {
    return this._http.post<Department>('api/com/departments', params);
  }

  getDepartment(organizationId: string): Observable<Department> {
    return this._http.get<Department>(`api/com/departments/${organizationId}`);
  }

  updateDepartment(organizationId: string, params: DepartmentUpdateParams): Observable<Department> {
    return this._http.put<Department>(`api/com/departments/${organizationId}`, params);
  }
}
