import { Injectable } from '@angular/core';
import { AsyncSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, tap } from 'rxjs/operators';

import { NeverError } from '../models/error.model';
import {
  Organization,
  OrganizationCreateParams,
  OrganizationUpdateParams,
  Organizations,
  isOrganization,
} from '../models/organization.model';
import { User, UserQueryParams } from '../models/user.model';
import { DistinctSubject, recursiveQuery } from '../models/utility.model';
import { WebSocketSyncData } from '../models/web-socket.model';
import { AuthUsecase } from '../usecases/auth.usecase';
import { OrganizationGateway } from '../usecases/organization.gateway';
import { OrganizationUsecase } from '../usecases/organization.usecase';
import { UserGateway } from '../usecases/user.gateway';
import { WebSocketUsecase } from '../usecases/web-socket.usecase';

@Injectable()
export class OrganizationInteractor extends OrganizationUsecase {
  get organization$(): Observable<Organization> {
    return this._organizations.pipe(
      map(organizations => organizations.get(this._organizationId || '')),
      filter(isOrganization),
    );
  }
  get organizations$(): Observable<Organizations> {
    return this._organizations.pipe(
      map(organizations =>
        organizations.values().map(organization => ({
          ...organization,
          users$: recursiveQuery<UserQueryParams, User>(params => this._userGateway.listUsers(params), {
            organizationId: organization.organizationId,
          }),
        })),
      ),
      map(organizations => new Organizations(organizations)),
    );
  }

  private _organizationId?: string;
  private readonly _organizations = new DistinctSubject<Organizations>(new Organizations());

  constructor(
    private _authUsecase: AuthUsecase,
    private _webSocketUsecase: WebSocketUsecase,
    private _organizationGateway: OrganizationGateway,
    private _userGateway: UserGateway,
  ) {
    super();

    if (!this._webSocketUsecase.enabled) {
      this._authUsecase.authState$
        .pipe(
          map(({ status }) => status === 'signedIn'),
          distinctUntilChanged(),
        )
        .subscribe(signedIn => (signedIn ? this.onSignIn() : this.onSignOut()));
      return;
    }

    this._webSocketUsecase.isOpen$.subscribe(isOpen => (isOpen ? this.onSignIn() : this.onSignOut()));
    this._webSocketUsecase.message$
      .pipe(
        filter(message => message.action === 'sync' && message.data?.source === 'organization'),
        map(({ data }) => data as WebSocketSyncData<Organization>),
      )
      .subscribe(data => {
        switch (data.reason) {
          case 'create':
          case 'update':
            this._organizations.next(this._organizations.value.set(data.payload as Organization));
            break;
          case 'delete':
            this._organizations.next(this._organizations.value.delete((data.payload as Organization).organizationId));
            break;
          default:
            throw new NeverError(data.reason);
        }
      });
  }

  createOrganization(params: OrganizationCreateParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._organizationGateway.createOrganization(params).subscribe({
      next: createdOrganization => this._organizations.next(this._organizations.value.set(createdOrganization)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  updateOrganization(organizationId: string, params: OrganizationUpdateParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._organizationGateway.updateOrganization(organizationId, params).subscribe({
      next: updatedOrganization => this._organizations.next(this._organizations.value.set(updatedOrganization)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  deleteOrganization(organizationId: string): Observable<never> {
    const result = new AsyncSubject<never>();
    this._organizationGateway.deleteOrganization(organizationId).subscribe({
      next: () => this._organizations.next(this._organizations.value.delete(organizationId)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  reload(): void {
    this.onSignIn();
  }

  private onSignIn(): void {
    this._authUsecase.payload$
      .pipe(
        tap(({ organizationId }) => (this._organizationId = organizationId)),
        mergeMap(({ organizationId }) =>
          organizationId
            ? this._organizationGateway.getOrganization(organizationId).pipe(map(organization => [organization]))
            : recursiveQuery(params => this._organizationGateway.listOrganizations(params), {}),
        ),
      )
      .subscribe(organizations => this._organizations.next(new Organizations(organizations)));
  }

  private onSignOut(): void {
    this._organizationId = undefined;
    this._organizations.next(new Organizations());
  }
}
