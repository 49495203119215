import { Observable } from 'rxjs';

import { Department, DepartmentCreateParams, DepartmentUpdateParams } from '../models/department.model';

export abstract class DepartmentUsecase {
  abstract get department$(): Observable<Department | undefined>;

  abstract createDepartment(params: DepartmentCreateParams): Observable<never>;
  abstract getDepartment(organizationId: string): Observable<never>;
  abstract updateDepartment(organizationId: string, params: DepartmentUpdateParams): Observable<never>;
}
