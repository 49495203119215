import { Prop, isObject, isString } from './utility.model';

export const FACTORIES = ['kashima', 'yodogawa'] as const;
export type Factory = (typeof FACTORIES)[number];

export type Group = {
  groupId: string;
  name: string;
};

type DepartmentRequiredProps = {
  organizationId: string;
  departmentCode: string;
  factory: Factory;
  groups: Group[];
};

type DepartmentOptionalProps = object;

type DepartmentProvidedProps = {
  updatedAt: number;
  version: number;
};

export type DepartmentCreateParams = DepartmentRequiredProps & DepartmentOptionalProps;

export type DepartmentUpdateParams = Partial<Pick<DepartmentRequiredProps, 'groups'>> & DepartmentOptionalProps;

export type Department = DepartmentRequiredProps & DepartmentOptionalProps & DepartmentProvidedProps;

function isGroup(value: unknown): value is Group {
  const sProps: Prop<Group, string>[] = ['groupId', 'name'];
  return isObject(value) && sProps.every(prop => isString(value[prop]));
}

function isGroupArray(value: unknown): value is Group[] {
  return Array.isArray(value) && value.every(isGroup);
}

export function isDepartmentCreateParams(value: unknown): value is DepartmentCreateParams {
  const sProps: Prop<DepartmentCreateParams, string>[] = ['organizationId', 'departmentCode'];
  return (
    isObject(value) &&
    sProps.some(prop => isString(value[prop])) &&
    FACTORIES.some(v => v === value['factory']) &&
    isGroupArray(value['groups'])
  );
}

export function isDepartmentUpdateParams(value: unknown): value is DepartmentUpdateParams {
  const sProps: Prop<DepartmentCreateParams, string>[] = ['departmentCode'];
  return (
    isObject(value) &&
    (sProps.some(prop => isString(value[prop])) || FACTORIES.some(v => v === value['factory']) || isGroupArray(value['groups']))
  );
}
