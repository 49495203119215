import { Observable } from 'rxjs';

import { Organizer, OrganizerCreateParams, OrganizerQueryParams, OrganizerUpdateParams } from '../models/organizer.model';
import { ListResponse } from '../models/utility.model';

export abstract class OrganizerGateway {
  abstract listOrganizers(params: OrganizerQueryParams): Observable<ListResponse<Organizer>>;
  abstract createOrganizer(params: OrganizerCreateParams): Observable<Organizer>;
  abstract updateOrganizer(organizerId: string, params: OrganizerUpdateParams): Observable<Organizer>;
  abstract deleteOrganizer(organizerId: string): Observable<void>;
}
