import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDropdownConfig, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { hide } from '@popperjs/core';
import { Observable } from 'rxjs';

import { AuthUsecase, ProgressUsecase, Toast, ToastUsecase } from '@daikin-tic/dxone-com-lib';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  progress$?: Observable<boolean>;
  toasts$?: Observable<Toast[]>;

  constructor(
    private _router: Router,
    private _dropdownConfig: NgbDropdownConfig,
    private _modalConfig: NgbModalConfig,
    private _authUsecase: AuthUsecase,
    private _progressUsecase: ProgressUsecase,
    private _toastUsecase: ToastUsecase,
  ) {
    this._dropdownConfig.popperOptions = ({ modifiers = [], ...surplus }) => ({ ...surplus, modifiers: [...modifiers, hide] });
    this._modalConfig.backdrop = 'static';
    this._modalConfig.keyboard = false;
    this._modalConfig.scrollable = true;
  }

  ngOnInit(): void {
    this._authUsecase.authState$.subscribe(authState => {
      switch (authState.status) {
        case 'signIn':
          if (!location.pathname.startsWith('/auth')) {
            this._router.navigate(['auth']);
          }
          break;
        case 'signedIn':
          if (!location.pathname.startsWith('/app')) {
            this._router.navigate(['app']);
          }
          break;
        default:
          // nop
          break;
      }
    });

    this.progress$ = this._progressUsecase.progress$;
    this.toasts$ = this._toastUsecase.toasts$;
  }
}
