import { NgModule } from '@angular/core';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

import { AuthInteractor } from './core/interactors/auth.interactor';
import { DepartmentInteractor } from './core/interactors/department.interactor';
import { MgmtDeptInteractor } from './core/interactors/mgmt-dept.interactor';
import { OrganizationInteractor } from './core/interactors/organization.interactor';
import { OrganizerInteractor } from './core/interactors/organizer.interactor';
import { ProgressInteractor } from './core/interactors/progress.interactor';
import { ToastInteractor } from './core/interactors/toast.interactor';
import { UserInteractor } from './core/interactors/user.interactor';
import { WebSocketInteractor } from './core/interactors/web-socket.interactor';
import { AuthService } from './core/services/auth.service';
import { DatadogService } from './core/services/datadog.service';
import { DepartmentService } from './core/services/department.service';
import { MgmtDeptService } from './core/services/mgmt-dept.service';
import { OrganizationService } from './core/services/organization.service';
import { OrganizerService } from './core/services/organizer.service';
import { UserService } from './core/services/user.service';
import { AuthGateway } from './core/usecases/auth.gateway';
import { AuthUsecase } from './core/usecases/auth.usecase';
import { DatadogGateway } from './core/usecases/datadog.gateway';
import { DepartmentGateway } from './core/usecases/department.gateway';
import { DepartmentUsecase } from './core/usecases/department.usecase';
import { MgmtDeptGateway } from './core/usecases/mgmt-dept.gateway';
import { MgmtDeptUsecase } from './core/usecases/mgmt-dept.usecase';
import { OrganizationGateway } from './core/usecases/organization.gateway';
import { OrganizationUsecase } from './core/usecases/organization.usecase';
import { OrganizerGateway } from './core/usecases/organizer.gateway';
import { OrganizerUsecase } from './core/usecases/organizer.usecase';
import { ProgressUsecase } from './core/usecases/progress.usecase';
import { ToastUsecase } from './core/usecases/toast.usecase';
import { UserGateway } from './core/usecases/user.gateway';
import { UserUsecase } from './core/usecases/user.usecase';
import { WebSocketUsecase } from './core/usecases/web-socket.usecase';

dayjs.extend(timezone);

@NgModule({
  providers: [
    { provide: AuthUsecase, useClass: AuthInteractor },
    { provide: DepartmentUsecase, useClass: DepartmentInteractor },
    { provide: MgmtDeptUsecase, useClass: MgmtDeptInteractor },
    { provide: OrganizationUsecase, useClass: OrganizationInteractor },
    { provide: OrganizerUsecase, useClass: OrganizerInteractor },
    { provide: ProgressUsecase, useClass: ProgressInteractor },
    { provide: ToastUsecase, useClass: ToastInteractor },
    { provide: UserUsecase, useClass: UserInteractor },
    { provide: WebSocketUsecase, useClass: WebSocketInteractor },
    { provide: AuthGateway, useClass: AuthService },
    { provide: DatadogGateway, useClass: DatadogService },
    { provide: DepartmentGateway, useClass: DepartmentService },
    { provide: MgmtDeptGateway, useClass: MgmtDeptService },
    { provide: OrganizationGateway, useClass: OrganizationService },
    { provide: OrganizerGateway, useClass: OrganizerService },
    { provide: UserGateway, useClass: UserService },
  ],
})
export class LibModule {}
