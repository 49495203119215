import { Observable } from 'rxjs';

import { Organization, OrganizationCreateParams, OrganizationUpdateParams, Organizations } from '../models/organization.model';

export abstract class OrganizationUsecase {
  abstract get organization$(): Observable<Organization>;
  abstract get organizations$(): Observable<Organizations>;

  abstract createOrganization(params: OrganizationCreateParams): Observable<never>;
  abstract updateOrganization(organizationId: string, params: OrganizationUpdateParams): Observable<never>;
  abstract deleteOrganization(organizationId: string): Observable<never>;

  abstract reload(): void;
}
