import { Injectable } from '@angular/core';
import { AsyncSubject, Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

import { Department, DepartmentCreateParams, DepartmentUpdateParams } from '../models/department.model';
import { NeverError } from '../models/error.model';
import { DistinctSubject } from '../models/utility.model';
import { WebSocketSyncData } from '../models/web-socket.model';
import { AuthUsecase } from '../usecases/auth.usecase';
import { DepartmentGateway } from '../usecases/department.gateway';
import { DepartmentUsecase } from '../usecases/department.usecase';
import { WebSocketUsecase } from '../usecases/web-socket.usecase';

@Injectable()
export class DepartmentInteractor extends DepartmentUsecase {
  get department$(): Observable<Department | undefined> {
    return this._department;
  }

  private readonly _department = new DistinctSubject<Department | undefined>(undefined);

  constructor(
    private _webSocketUsecase: WebSocketUsecase,
    private _authUsecase: AuthUsecase,
    private _departmentGateway: DepartmentGateway,
  ) {
    super();

    if (!this._webSocketUsecase.enabled) {
      return;
    }

    this._webSocketUsecase.isOpen$.subscribe(isOpen => (isOpen ? this.onSignIn() : this.onSignOut()));
    this._webSocketUsecase.message$
      .pipe(
        filter(message => message.action === 'sync' && message.data?.source === 'department'),
        map(({ data }) => data as WebSocketSyncData<Department>),
      )
      .subscribe(data => {
        switch (data.reason) {
          case 'create':
          case 'update': {
            const department = data.payload as Department;
            if (
              !this._department.value?.organizationId ||
              (this._department.value?.organizationId === department.organizationId && this._department.value?.version < department.version)
            ) {
              this._department.next(department);
            }
            break;
          }
          case 'delete':
            if (this._department.value?.organizationId === (data.payload as Department).organizationId) {
              this._department.next({} as Department);
            }
            break;
          default:
            throw new NeverError(data.reason);
        }
      });
  }

  createDepartment(params: DepartmentCreateParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._departmentGateway.createDepartment(params).subscribe({
      next: createdOrganization => this._department.next(createdOrganization),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  getDepartment(organizationId: string): Observable<never> {
    const result = new AsyncSubject<never>();
    this._departmentGateway.getDepartment(organizationId).subscribe({
      next: department => this._department.next(department),
      error: () => this._department.next({} as Department),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  updateDepartment(organizationId: string, params: DepartmentUpdateParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._departmentGateway.updateDepartment(organizationId, params).subscribe({
      next: updatedOrganization => this._department.next(updatedOrganization),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  private onSignIn(): void {
    this._authUsecase.payload$
      .pipe(mergeMap(({ organizationId }) => this._departmentGateway.getDepartment(organizationId || '')))
      .subscribe({
        next: department => this._department.next(department),
        error: () => this._department.next({} as Department),
      });
  }

  private onSignOut(): void {
    this._department.next(undefined);
  }
}
